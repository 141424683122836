import styled from 'styled-components'
import { Col } from '@baevra/grid'
import { up } from 'src/utils/media'

const Wrapper = styled(Col)`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 0;
  user-select: none;
  margin-bottom: 7rem;
  
  ${up('mobile')} {
    width: 48%;
    max-width: 48%;
    margin-bottom: 7rem;

    &:nth-child(odd) {
      margin-right: 4%;
    }
  }

  ${up('tablet')} {
    width: calc(380 / 1780 * 100%);
    max-width: calc(380 / 1780 * 100%);
    margin-right: calc((100% - 380 / 1780 * 100% * 4) / 3);
    margin-bottom: 7rem;

    &:nth-child(odd) {
      margin-right: calc((100% - 380 / 1780 * 100% * 4) / 3);
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  ${up('desktop')} {
    margin-bottom: 14rem;
  }

  ${up('largeDesktop')} {
    margin-bottom: 25rem;
  }

  & > div {
    word-break: break-word;
    overflow: hidden;
  }

  .title {
    display: flex;
    align-items: center;
    max-width: 100%;
    white-space: normal;

    ${up('tablet')} {
      max-height: 9rem;
    }

    ${up('desktop')} {
      max-height: 11rem;
    }

    ${up('largeDesktop')} {
      max-height: 26rem;
    }

    & h4 {
      color: inherit;
    }

    a {
      transition: all .5s ease-in-out;

      &:hover {
        color: ${props => props.theme.colors.button};
      }
    }

  }

  .date {
    margin-bottom: 1.5rem;

    ${up('tablet')} {
      margin-bottom: .75em;
    }

    & > div > span:nth-child(1) {
      line-height: 1.5;
    }
  }

  .poster {
    overflow: hidden;
    user-select: none;
    margin-bottom: 1em;
    width: 100%;
    height: auto;

    a {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: calc(240 / 380 * 100%);
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        object-fit: cover;
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transition: transform 2s ease-out;
        user-select: none;
      }

      &:hover img {
        transform: translate(-50%,-50%) scale(1.05);
      }
    }

  }
`

export default Wrapper
