import React from 'react'
import StyledH4 from './style'

interface Props {
  className?: string
  italic?: boolean
  float?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const H4:React.FC<Props> = props => {
  const { className, children, italic, float, ...other } = props
  return (
    <StyledH4
      className={className}
      italic={italic}
      float={float}
      {...other}
    >
      {children ?? null}
    </StyledH4>
  )
}

export default H4
