import React from 'react'
import { Link } from 'gatsby'
import H4 from 'src/components/Typography/H4'
import Date from 'src/components/Typography/Date'
import createShortYear from 'src/utils/createShortYear'
import { CardProps } from 'src/components/Card'
import Wrapper from './style'

const NewsCard = (props: CardProps) => {
  const { title, date, image, slug } = props.data
  const { className } = props
  const link = `/news/${slug}`

  return (
    <Wrapper className={className}>
      <div className="poster">
        <Link to={link}>
          <img src={image} alt="poster" />
        </Link>
      </div>
      <div className="date">
        <Date
          day={date?.day ?? ''}
          month={date?.month ?? ''}
          year={createShortYear(date?.year ?? '')}
        />
      </div>
      <div className="title">
        <Link to={link}>
          <H4 dangerouslySetInnerHTML={{ __html: title }} />
        </Link>
      </div>
    </Wrapper>
  )
}

export default NewsCard
