import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/layout'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import NewsList from 'src/sections/NewsList'
import removeHTMLTags from 'src/utils/removeHTMLTags'
import createDateComponent from 'src/utils/createDateComponent'
import SEO from 'src/components/SEO'

const createNews = (wordpressNews: any) => {
  return wordpressNews.edges
    .map((edge: any) => {
      const news = edge.node
      const title = removeHTMLTags(news.title)
      const subtitle = removeHTMLTags(news.excerpt)
      const slug = news.slug
      const date = createDateComponent(news.date)
      const image = news.better_featured_image?.source_url

      return {
        title,
        subtitle,
        slug,
        image,
        date
      }
    })
}

const NewsPage = (props: any) => {
  const news = createNews(props.data.allWordpressWpNews)

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <Navbar />
        <NewsList data={news} />
      </main>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query {
    allWordpressWpNews {
      edges {
        node {
          better_featured_image {
            source_url
          }
          title
          slug
          excerpt
          date
        }
      }
    }
  }
`
