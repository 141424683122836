import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  italic?: boolean
  float?: string
}

const StyledH4 = styled.h4<Props>`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.6rem;
  line-height: 1.36;
  font-weight: 500;
  font-style: ${props => props.italic ? 'italic' : 'normal'};

  ${up('mobile')} {
    font-size: 1.8rem;
  }

  ${up('desktop')} {
    font-size: 2.2rem;
  }

  ${up('largeDesktop')} {
    font-size: 4.6rem;
  }

  ${props => props.float && `text-align: ${props.float};`}
`

export default StyledH4
