import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  max-width: calc(100vw - 4rem);
  margin: 5rem auto;
  margin-bottom: 0!important;
  flex-flow: row wrap;

  ${up('tablet')} {
    max-width: calc(100vw - 14rem);
    margin: 8rem auto;
  }

  ${up('desktop')} {
    margin: 13rem auto;
  }

  ${up('largeDesktop')} {
    margin: 25rem auto;
  }
`

export default Wrapper
