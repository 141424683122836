import React from 'react'
import Card from 'src/components/Card/News'
import { CardProps } from 'src/components/Card'
import Wrapper from './style'

interface Props {
  data: CardProps['data'][]
}

const NewsList:React.FC<Props> = props => {
  return (
    <Wrapper>
      {props.data.map(item => (
          <Card
            key={item.title}
            className="menu-item"
            data={item}
          />
        ))
      }
    </Wrapper>
  )
}

export default NewsList
